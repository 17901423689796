<template>
  <v-layout wrap>
    <v-flex xs8>
      <AppTitle title="Fechas importantes enviadas">
        <template slot="subtitle">
          Desde el
          {{ filter.dateStart | moment('DD-MM-YYYY') }}
          al
          {{ filter.dateEnd | moment('DD-MM-YYYY') }}
        </template>
      </AppTitle>
    </v-flex>
    <v-flex xs-4 class="text-xs-right">
      <v-menu v-model="menu" :close-on-click="false" :close-on-content-click="false" :nudge-width="100" offset-x>
        <v-btn slot="activator" color="grey" dark flat icon>
          <v-icon>more_vert</v-icon>
        </v-btn>

        <v-card>
          <v-card-text>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-dialog
                  ref="dialogStart"
                  v-model="modalDateStart"
                  :return-value.sync="filter.dateStart"
                  lazy
                  full-width
                  width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="filter.dateStart"
                    label="Fecha desde"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="filter.dateStart" locale="es-ES" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="modalDateStart = false">
                      Volver
                    </v-btn>
                    <v-btn color="primary" flat @click="$refs.dialogStart.save(filter.dateStart)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-dialog
                  ref="dialogEnd"
                  v-model="modalDateEnd"
                  :return-value.sync="filter.dateEnd"
                  lazy
                  full-width
                  width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="filter.dateEnd"
                    label="Fecha Hasta"
                    prepend-icon="event"
                    readonly
                  ></v-text-field>
                  <v-date-picker v-model="filter.dateEnd" locale="es-ES" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="modalDateEnd = false">
                      Volver
                    </v-btn>
                    <v-btn color="primary" flat @click="$refs.dialogEnd.save(filter.dateEnd)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat @click="menu = false"> Volver</v-btn>
            <v-btn color="primary" flat @click="filterPolls()"> Filtrar </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-flex>
    <v-flex xs12>
      <v-layout>
        <v-flex xs12 sm6 md4>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 text-xs-right>
                  <h2 class="display-2" style="font-weight: 300;">
                    {{ total }}
                  </h2>
                  <h3 class="subheading">Total</h3>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md4>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 text-xs-right>
                  <h2 class="display-2" style="font-weight: 300;">
                    {{ emails }}
                  </h2>
                  <h3 class="subheading">Emails</h3>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md4>
          <v-card>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 text-xs-right>
                  <h2 class="display-2" style="font-weight: 300;">
                    {{ sms }}
                  </h2>
                  <h3 class="subheading">SMS</h3>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <div v-if="loading" class="loading-table"></div>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        class="elevation-2"
        no-data-text="No hay fechas importantes enviadas con las fechas ingresadas"
        :pagination.sync="pagination"
        :rows-per-page-items="rowsPerPage"
      >
        <v-progress-linear v-if="loading" slot="progress" color="primary" indeterminate> </v-progress-linear>
        <template slot="headers" slot-scope="props">
          <tr>
            <th v-for="header in props.headers" :key="header.text" :class="`text-xs-${header.align}`">
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr>
            <td class="text-xs-center">
              {{ props.item.type_send }}
            </td>
            <td class="text-xs-left">
              <router-link target="_blank" :to="`/cupones-de-descuento/${props.item.id_cupon}`">
                {{ props.item.cupon }}
              </router-link>
            </td>
            <td class="text-xs-left">
              <router-link target="_blank" :to="`/cliente/${props.item.id_user}`">
                {{ props.item.name }}
              </router-link>
            </td>
            <td class="text-xs-right">
              {{ props.item.phone }}
            </td>
            <td class="text-xs-left">
              {{ props.item.email }}
            </td>
            <td class="text-xs-center">
              <AppStatus :value="props.item.status_sms"></AppStatus>
            </td>
            <td class="text-xs-center">
              <AppStatus :value="props.item.status_email"></AppStatus>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import qs from 'qs'
import { GET_DATE_IMPORTANT } from '../../config'
import AppTitle from '../useful/title.vue'
import AppStatus from '../useful/status.vue'

export default {
  name: 'Encuestas',
  metaInfo: {
    title: 'Fechas importantes'
  },
  components: {
    AppTitle,
    AppStatus
  },
  data() {
    return {
      items: [],
      menu: false,
      loading: false,
      modalDateStart: false,
      modalDateEnd: false,
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      filter: {
        dateStart: this.$moment()
          .subtract(3, 'days')
          .format('YYYY-MM-DD'),
        dateEnd: this.$moment().format('YYYY-MM-DD'),
        type: 1
      },
      headers: [
        { text: 'Tipo', align: 'left' },
        { text: 'Cupon', align: 'left' },
        { text: 'Usuario', align: 'left' },
        { text: 'Phone', align: 'right' },
        { text: 'Email', align: 'left' },
        { text: 'Envio SMS', align: 'center' },
        { text: 'Envio Email', align: 'center' }
      ]
    }
  },
  computed: {
    total() {
      return this.items.length
    },
    emails() {
      const r = this._.filter(this.items, ['status_email', 1])
      return r.length
    },
    sms() {
      const r = this._.filter(this.items, ['status_sms', 1])
      return r.length
    }
  },
  mounted() {
    this.getStaticsDateImportants()
  },
  methods: {
    async getStaticsDateImportants() {
      this.loading = true
      try {
        const res = await this.$http.get(`${GET_DATE_IMPORTANT}?${qs.stringify({ filters: this.filter })}`)
        this.items = res.data
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', { active: true, text: 'Ocurrio un error, intente nuevamente' })
      }
    },
    filterPolls() {
      this.menu = false
      this.getStaticsDateImportants()
    }
  }
}
</script>
